/*
| Developed by Starton
| Filename : CreateAccountButtons.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { GitHub, Google, MailOutlineOutlined } from '@mui/icons-material'
import { StartonButton } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import Link from 'next/link'
import { useMaintenances } from '@/services/fapi'
import { GtagService } from '@/services/gtag/gtag.service'
import { providerRedirect } from '@/config/urls/project-builder'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

export interface CreateAccountButtonsProps {
	onEmailClick: VoidFunction
	eventType: 'oidc-login' | 'oidc-register'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const CreateAccountButtons: React.FC<CreateAccountButtonsProps> = ({ onEmailClick }) => {
	const { t } = useTranslation()
	const { data: maintenancesData } = useMaintenances()

	// Get OIDC urls
	// ----------------------------------------------------------------------------
	const getRedirectUrl = React.useCallback((provider: string) => {
		if (typeof window !== 'undefined') {
			const spaceTenant = window.location.hostname.split('.')[0]

			if (spaceTenant !== 'app') {
				return providerRedirect(provider, spaceTenant)
			}
		}

		return providerRedirect(provider)
	}, [])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Grid container direction="column" gap={2}>
			<Grid item>
				<Tooltip
					title={
						!maintenancesData?.data?.attributes?.authRegister?.activated
							? undefined
							: maintenancesData?.data?.attributes?.authRegister?.content ?? undefined
					}
				>
					<Link
						href={getRedirectUrl('google')}
						onClick={() => {
							GtagService.createUserEvent({
								name: 'oidc-login',
							})
						}}
						data-testid={'google-login-button'}
					>
						<StartonButton
							fullWidth
							variant={'outlined'}
							size={'large'}
							startIcon={<Google />}
							disabled={maintenancesData?.data?.attributes?.authRegister?.activated ?? undefined}
						>
							{t(`common:common.continueWithGoogle`)}
						</StartonButton>
					</Link>
				</Tooltip>
			</Grid>
			<Grid item>
				<Tooltip
					title={
						!maintenancesData?.data?.attributes?.authRegister?.activated
							? undefined
							: maintenancesData?.data?.attributes?.authRegister?.content ?? undefined
					}
				>
					<Link
						href={getRedirectUrl('github')}
						onClick={() => {
							GtagService.createUserEvent({
								name: 'oidc-login',
							})
						}}
						data-testid={'github-login-button'}
					>
						<StartonButton
							fullWidth
							variant={'outlined'}
							size={'large'}
							startIcon={<GitHub />}
							disabled={maintenancesData?.data?.attributes?.authRegister?.activated ?? undefined}
						>
							{t(`common:common.continueWithGithub`)}
						</StartonButton>
					</Link>
				</Tooltip>
			</Grid>
			<Box display="flex" gap={2} width="100%" alignItems="center" padding={2}>
				<Box width="100%" border="1px solid" color="divider" height={0} />
				<Typography color="text.secondary">OR</Typography>
				<Box width="100%" border="1px solid" color="divider" height={0} />
			</Box>
			<Tooltip
				title={
					!maintenancesData?.data?.attributes?.authRegister?.activated
						? undefined
						: maintenancesData?.data?.attributes?.authRegister?.content ?? undefined
				}
			>
				<StartonButton
					fullWidth
					variant={'outlined'}
					size={'large'}
					startIcon={<MailOutlineOutlined />}
					onClick={onEmailClick}
					disabled={maintenancesData?.data?.attributes?.authRegister?.activated ?? undefined}
					data-testid={'email-login-button'}
				>
					{t('common:common.continueWithEmail')}
				</StartonButton>
			</Tooltip>
		</Grid>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
CreateAccountButtons.displayName = 'CreateAccountButtons'
