/*
| Developed by Starton
| Filename: CollapsableBox.tsx
| Author: Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { motion } from 'framer-motion'
import { Box, BoxProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface CollapsableBoxProps extends Pick<BoxProps, 'sx'> {
	open: boolean
	children: React.ReactNode
	removeGap?: number
}

/*
|--------------------------------------------------------------------------
| Sub Component
|--------------------------------------------------------------------------
*/
const MotionBox = motion(Box)

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const CollapsableBox: React.FC<CollapsableBoxProps> = ({ open, children, removeGap = 0, ...props }) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<MotionBox
			{...props}
			initial={{ height: open ? 'auto' : 0, overflow: 'hidden', marginBottom: open ? 0 : removeGap }}
			animate={{ height: open ? 'auto' : 0, overflow: 'hidden', marginBottom: open ? 0 : removeGap }}
		>
			{children}
		</MotionBox>
	)
}
