/*
| Developed by Starton
| Filename: Login.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { StartonButton, StartonTextField } from '@starton/react-ui'
import { Box } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import { AxiosError } from 'axios'
import { ArrowBackOutlined } from '@mui/icons-material'
import { usePostHog } from 'posthog-js/react'
import { PasswordField } from '../../../formik/PasswordField'
import { StartonCTAQuestion } from '../../../common/StartonCTAQuestion'
import { LoginState } from '@/pages/auth/login'
import { UrlsConfig } from '@/config/urls/urls.config'
import { useMaintenances } from '@/services/fapi'
import { authLogin } from '@/services/auth-api/endpoints/auth'
import { GtagService } from '@/services/gtag/gtag.service'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LoginProps {
	setState: React.Dispatch<React.SetStateAction<LoginState>>
}

interface FormikInitialValues {
	email: string
	password: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Login: React.FC<LoginProps> = ({ setState }) => {
	const { t } = useTranslation('login')
	const router = useRouter()
	const posthog = usePostHog()
	const { data: maintenancesData } = useMaintenances()
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	// Memoization
	//--------------------------------------------------------------------------
	const initialValues: FormikInitialValues = React.useMemo(() => ({ password: '', email: '' }), [])
	const validationSchema = React.useMemo(
		() =>
			Yup.object().shape({
				email: Yup.string()
					.email('Email must be a valid email address')
					.required('Email is required')
					.matches(/@([\w-]+\.)+[\w-]{2,4}$/, 'Email must be a valid email address'),
				password: Yup.string().required('Password is required'),
			}),
		[],
	)

	// Methods
	//--------------------------------------------------------------------------
	const handleLogin = async (values: FormikInitialValues, formikHelpers: FormikHelpers<FormikInitialValues>) => {
		if (isLoading) return
		setIsLoading(true)
		try {
			const { data } = await authLogin({
				email: values.email,
				password: values.password,
			})
			GtagService.createUserEvent({
				name: 'login',
				userId: data.id,
			})

			posthog?.identify(data.id, {
				email: data.email,
				name: data.name,
			})

			await router.push(UrlsConfig.home)
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 403) {
					await router.push(UrlsConfig.auth.verification(values.email))
					return
				}
				formikHelpers.setFieldError('password', t('auth/login:errors.invalidCredentials'))
			}
			setIsLoading(false)
		}
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<Formik initialValues={initialValues} onSubmit={handleLogin} validationSchema={validationSchema}>
			{({ resetForm }) => (
				<Form
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 32,
						width: '100%',
					}}
				>
					<Field
						name="email"
						component={StartonTextField}
						label="Email"
						placeholder="mail@company.com"
						disabled={isLoading}
						data-testid="email"
					/>
					<PasswordField disabled={isLoading} />
					<Box display="flex" gap={2}>
						<StartonButton
							color="secondary"
							startIcon={<ArrowBackOutlined />}
							onClick={() => {
								setState('buttons')
								void resetForm()
							}}
							data-testid="back"
						>
							{t('common:common.back')}
						</StartonButton>
						<StartonButton
							type="submit"
							variant="contained"
							isLoading={isLoading}
							disabled={isLoading}
							data-testid={'submit'}
						>
							Login
						</StartonButton>
					</Box>
					<StartonCTAQuestion
						text={t('auth/login:forgotPassword.text')}
						cta={{
							text: t('auth/login:forgotPassword.buttonText'),
							link: UrlsConfig.auth.forgotPassword,
							disabled: {
								active: maintenancesData?.data?.attributes?.authForgotPassword?.activated ?? false,
								message: maintenancesData?.data?.attributes?.authForgotPassword?.content,
							},
							dataTestId: 'forgot-password',
						}}
					/>
				</Form>
			)}
		</Formik>
	)
}
