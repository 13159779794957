/*
| Developed by Starton
| Filename : StartonCTAQuestion.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Grid, Typography } from '@mui/material'
import { StartonLink, StartonLinkProps } from '../StartonLink'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonCTAQuestionProps {
	text: string
	cta: {
		link: string
		text: string
		disabled?: StartonLinkProps['disabled']
		dataTestId?: string
	}
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonCTAQuestion: React.FC<StartonCTAQuestionProps> = (props) => {
	return (
		<Grid container spacing={1}>
			<Grid item>
				<Typography fontWeight={400}>{props.text}</Typography>
			</Grid>
			<Grid item>
				<StartonLink href={props.cta.link} disabled={props.cta.disabled} data-testid={props.cta.dataTestId}>
					{props.cta.text}
				</StartonLink>
			</Grid>
		</Grid>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
StartonCTAQuestion.displayName = 'StartonCTAQuestion'
